<template>
  <div class="ma-4">
    <h3 class="mt-4 primary--text">Kata Pasien</h3>
    <v-row>
      <v-col cols="12" lg="12" md="12" sm="12">
        <v-card>
<!--          <v-card-title>-->
<!--            <v-text-field v-model="search" dense label="Cari Nama Pasien" class="ma-1" outlined-->
<!--                          @keyup="fetchData"></v-text-field>-->
<!--            <v-text-field v-model="search2" dense label="Cari No. Rekam Medis" class="ma-1" outlined-->
<!--                          @keyup="fetchData"></v-text-field>-->
<!--          </v-card-title>-->
          <v-data-table
            :headers="headers"
            :items="items"
            :search="search + ' ' + search2"
            :server-items-length="total"
            :loading="loading"
            :items-per-page="limit"
            height="750pt"
            fixed-header
            :disable-sort="true"
            @update:page="fetchData"
            @update:options="updatePagination"
          >
<!--            <template #[`item.id_rating_rawat_jalan`]="{ item }">-->
<!--              <v-btn-->
<!--                x-small-->
<!--                fab-->
<!--                dark-->
<!--                outlined-->
<!--                :loading="loading5"-->
<!--                :disabled="loading5"-->
<!--                color="primary white&#45;&#45;text"-->
<!--                @click="goToProfilPasien(item)"-->
<!--              >-->
<!--                <v-icon>mdi-pencil</v-icon>-->
<!--              </v-btn-->
<!--              >&nbsp;-->
<!--            </template>-->
            <template #[`item.no_rm`]="{item}">
              {{item.no_rm}}
            </template>
            <template #[`item.rate`]="{ item }">
<!--              {{ item.rate.list[8]  }}-->
              <v-list-item tree-line>
                <v-list-item-content>
                  <v-list-item-title><b>{{item.rate.list[8].label}} Dokter</b></v-list-item-title>
                  <v-list-item-title>{{item.rate.list[8].pertanyaan}}</v-list-item-title>
                  <v-list-item-subtitle><u class="text-danger">Kata Pasien : {{item.rate.list[8].value}}</u></v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
              <v-list-item tree-line>
                <v-list-item-content>
                  <v-list-item-title><b>{{item.rate.list[15].label}} Dokter</b></v-list-item-title>
                  <v-list-item-title>{{item.rate.list[15].pertanyaan}}</v-list-item-title>
                  <v-list-item-subtitle><u class="text-danger">Kata Pasien : {{item.rate.list[15].value}}</u></v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
<!--              1. {{ item.rate.list[8].pertanyaan  }}&nbsp;<br>Kata Pasien&nbsp;:&nbsp;<b class="text-danger"><u>{{ item.rate.list[8].value  }}</u></b><br>-->
<!--              2. {{ item.rate.list[15].pertanyaan  }}&nbsp;<br>Kata Pasien&nbsp;:&nbsp;<b class="text-danger"><u>{{ item.rate.list[15].value  }}</u></b>-->

            </template>

          </v-data-table>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { baseUrl } from '@/plugins/supports'

export default {
  data () {
    return {
      headers: [
        // Define your table headers here
        // { text: 'Aksi', value: 'id_rating_rawat_jalan', width: '80px', align: 'center' },
        { text: 'Tanggal', value: 'created_at', width: '180px', align: 'center' },
        { text: 'Pasien', value: 'created_by', width: '90px', align: 'center' },
        { text: 'Kata Pasien', value: 'rate', width: '790px', align: 'left' }

      ],
      items: [],
      search: '',
      loading5: false,
      search2: '',
      total: 0,
      page: 1,
      limit: 30,
      loading: false,
      user: JSON.parse(localStorage.getItem('user'))

    }
  },
  created () {
    this.fetchData()
  },
  methods: {
    goToProfilPasien (v) {
      this.$router.push({
        name: 'ModulIgd',
        // params: { no_rm: v.medrec_no, folio_id: v.folio_id, registration_id: v.registration_id }
        query: {
          no_rm: v.medrec_no,
          folio_id: v.folio_id,
          registration_id: v.registration_id,
          sub_unit_id: v.sub_unit_id,
          type: 'igd'
        }
      })
    },
    fetchData () {
      // console.log(this.search2)
      const subUnitId = this.user.employee_id

      this.loading = true
      const url = baseUrl + `rating/fetch-data?page=${this.page}&limit=${this.limit}&search=${this.search}&search2=${this.search2}&sub_unit_id=${subUnitId}`
      fetch(url)
        .then(response => response.json())
        .then(data => {
          var array = []
          data.items.forEach(obj => {
            // eslint-disable-next-line no-undef
            const rate = LZString.decompressFromBase64(obj.rate)
            const rateJson = JSON.parse(rate)
            // eslint-disable-next-line no-undef
            array.push({
              id_rating_rawat_jalan: obj.id_rating_rawat_jalan,
              no_rm: obj.no_rm,
              folio_id: obj.folio_id,
              rate: rateJson,
              created_at: obj.created_at,
              created_by: obj.created_by,
              expired: obj.expired,
              jenis: obj.jenis,
              rating_value: obj.rating_value
            })
          })

          this.items = array
          this.total = data.total
          this.loading = false
        })
        .catch(error => {
          console.error('Error fetching data:', error)
          this.loading = false
        })
    },
    updatePagination (options) {
      this.limit = options.itemsPerPage
      this.page = options.page
      this.fetchData()
    }
  }
}
</script>
